<template>
  <v-footer
    id="dashboard-core-footer"
    absolute
  >
    <v-container
      id="footer-container"
      fluid
    />
  </v-footer>
</template>

<script>
  import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'DashboardCoreFooter',
    data: () => ({
      logo: `${cdnUrl}/website/SwiftCheckIn.png`,
    }),
  };
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
    height: 0.1px
  #footer-container
    padding: 0px
    background-color: #CFD8DC
</style>
<style scoped>
.my-footer-style {
  color: #37474F;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 600;
}
.footer-img {
  margin-right: 25px;
  margin-top: 10px;
  max-width: 130px;
  margin-bottom: 10px;
}
</style>
